import React from "react"
import Layout from "../components/layout"
import { Row, Col } from "antd"
import servicesStyles from "../styles/services.module.scss"
import useShowOnScolled from "../useShowOnScrolled"

const Services = () => {
  const startingPosition = 75
  const shown = useShowOnScolled(startingPosition)

  return (
    <Layout>
      <Row align="middle" justify="center" gutter={[0, 48]}>
        <Col span={24}>
          <div className={servicesStyles.heroImage}>
            <div
              className={
                shown
                  ? `${servicesStyles.heroText} ${servicesStyles.heroTextAnimation}`
                  : `${servicesStyles.heroText}`
              }
            >
              <h2>Services</h2>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[0, 48]}>
        <Col
          span={10}
          offset={4}
          xs={{ span: 20, offset: 1 }}
          md={{ span: 14, offset: 2 }}
          lg={{ span: 10, offset: 4 }}
        >
          <div
            id={servicesStyles.opd}
            className={
              shown
                ? `${servicesStyles.image} ${servicesStyles.scroll}`
                : `${servicesStyles.image}`
            }
          />
        </Col>
        <Col span={6} offset={1}>
          <h4>Out Patient Department (OPD)</h4>
          <p>OPD department</p>
        </Col>
      </Row>
      <Row gutter={[0, 48]}>
        <Col
          span={10}
          offset={4}
          xs={{ span: 20, offset: 1 }}
          md={{ span: 14, offset: 2 }}
          lg={{ span: 10, offset: 4 }}
        >
          <div
            id={servicesStyles.xray}
            className={
              useShowOnScolled(startingPosition + 284)
                ? `${servicesStyles.image} ${servicesStyles.scroll}`
                : `${servicesStyles.image}`
            }
          />
        </Col>
        <Col span={6} offset={1}>
          <h4>X-Ray Room</h4>
        </Col>
      </Row>
      <Row gutter={[0, 48]}>
        <Col
          span={10}
          offset={4}
          xs={{ span: 20, offset: 1 }}
          md={{ span: 14, offset: 2 }}
          lg={{ span: 10, offset: 4 }}
        >
          <div
            id={servicesStyles.ot}
            className={
              useShowOnScolled(startingPosition + 284 * 2)
                ? `${servicesStyles.image} ${servicesStyles.scroll}`
                : `${servicesStyles.image}`
            }
          />
        </Col>
        <Col span={6} offset={1}>
          <h4>Operation Theatre</h4>
        </Col>
      </Row>
      <Row gutter={[0, 48]}>
        <Col
          span={10}
          offset={4}
          xs={{ span: 20, offset: 1 }}
          md={{ span: 14, offset: 2 }}
          lg={{ span: 10, offset: 4 }}
        >
          <div
            id={servicesStyles.cdc}
            className={
              useShowOnScolled(startingPosition + 284 * 3)
                ? `${servicesStyles.image} ${servicesStyles.scroll}`
                : `${servicesStyles.image}`
            }
          />
        </Col>
        <Col span={6} offset={1}>
          <h4>Child Development Centre</h4>
        </Col>
      </Row>
      <Row gutter={[0, 48]}>
        <Col
          span={10}
          offset={4}
          xs={{ span: 20, offset: 1 }}
          md={{ span: 14, offset: 2 }}
          lg={{ span: 10, offset: 4 }}
        >
          <div
            id={servicesStyles.general}
            className={
              useShowOnScolled(startingPosition + 284 * 4)
                ? `${servicesStyles.image} ${servicesStyles.scroll}`
                : `${servicesStyles.image}`
            }
          />
        </Col>
        <Col span={6} offset={1}>
          <h4>General Room</h4>
        </Col>
      </Row>
    </Layout>
  )
}

export default Services
